import { CgCPlusPlus } from "react-icons/cg";
import { useState } from "react";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiPython,
  DiGit,
  DiJava,
  DiPostgresql,
  DiRuby,
  DiGo,
  
} from "react-icons/di";

import {
    SiRubyonrails,
    SiTypescript,
    SiCucumber
} from "react-icons/si"
import {
    BiLogoSpringBoot,
    BiLogoAws,
    BiLogoHtml5,
    BiChevronDown, 
    BiChevronRight
} from "react-icons/bi"
import { Col, Row } from "react-bootstrap";

import "./skills.css";

const Skills = () => {
    const [showLanguages, setShowLanguages] = useState(true);
    const [showBackend, setShowBackend] = useState(false);
    const [showInfrastructure, setShowInfrastructure] = useState(false);
    const [showFrontend, setShowFrontend] = useState(false);

    return (
        <div className="articles-main-container">
            <div className="title articles-title">
                Professional Skillset
            </div>
            {/* <div className="articles-wrapper">
                <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
                    <Col xs={6} md={3} className="tech-icons">
                        <div className="icon">
                            <CgCPlusPlus />
                        </div>
                        C++
                    </Col>
                    <Col xs={6} md={3} className="tech-icons">
                        <div className="icon">
                            <DiJavascript1 />
                        </div>
                        JavaScript
                    </Col>
                    <Col xs={6} md={3} className="tech-icons">
                        <div className="icon">
                            <SiTypescript />
                        </div>
                        TypeScript
                    </Col>
                    <Col xs={6} md={3} className="tech-icons">
                        <div className="icon">
                            <DiPython />
                        </div>
                        Python
                    </Col>
                    <Col xs={6} md={3} className="tech-icons">
                        <div className="icon">
                            <DiJava />
                        </div>
                        Java
                    </Col>
                    <Col xs={6} md={3} className="tech-icons">
                        <div className="icon">
                            <DiRuby />
                        </div>
                        Ruby
                    </Col>
 
                    <Col xs={6} md={3} className="tech-icons">
                        <div className="icon">
                            <DiNodejs />
                        </div>
                        Node.js
                    </Col>
                    <Col xs={6} md={3} className="tech-icons">
                        <div className="icon">
                            <DiReact />
                        </div>
                        React
                    </Col>
                    <Col xs={6} md={3} className="tech-icons">
                        <div className="icon">
                            <BiLogoSpringBoot />
                        </div>
                        Spring Boot
                    </Col>
                    <Col xs={6} md={3} className="tech-icons">
                        <div className="icon">
                            <SiRubyonrails />
                        </div>
                        Ruby on Rails
                    </Col>
                    
                    <Col xs={6} md={3} className="tech-icons">
                        <div className="icon">
                            <DiGit />
                        </div>
                        Git
                    </Col>
                    <Col xs={6} md={3} className="tech-icons">
                        <div className="icon">
                            <DiMongodb />
                        </div>
                        MongoDB
                    </Col>
                    <Col xs={6} md={3} className="tech-icons">
                        <div className="icon">
                            <DiPostgresql />
                        </div>
                        PostgreSQL
                    </Col>

                    <Col xs={6} md={3} className="tech-icons">
                        <div className="icon">
                            <SiCucumber />
                        </div>
                        Cucumber
                    </Col>

                    <Col xs={6} md={3} className="tech-icons">
                        <div className="icon">
                            <BiLogoAws />
                        </div>
                        AWS
                    </Col>
                </Row>
            </div> */}
            <div className="articles-wrapper">
                <div>
                    <div onClick={() => setShowLanguages(!showLanguages)} style={{fontWeight:"600", fontSize:"22px",cursor: "pointer"}}>
                        {showLanguages ? <BiChevronDown /> : <BiChevronRight />} Languages
                    </div>
                    {showLanguages && (
                        <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
                            <Col xs={6} md={3} className="tech-icons">
                                <div className="icon">
                                    <CgCPlusPlus />
                                </div>
                                C++
                            </Col>
                            <Col xs={6} md={3} className="tech-icons">
                                <div className="icon">
                                    <DiPython />
                                </div>
                                Python
                            </Col>
                            <Col xs={6} md={3} className="tech-icons">
                                <div className="icon">
                                    <DiGo />
                                </div>
                                Golang
                            </Col>
                            <Col xs={6} md={3} className="tech-icons">
                                <div className="icon">
                                    <DiJavascript1 />
                                </div>
                                JavaScript
                            </Col>
                            <Col xs={6} md={3} className="tech-icons">
                                <div className="icon">
                                    <SiTypescript />
                                </div>
                                TypeScript
                            </Col>
                            <Col xs={6} md={3} className="tech-icons">
                                <div className="icon">
                                    <DiJava />
                                </div>
                                Java
                            </Col>
                            <Col xs={6} md={3} className="tech-icons">
                                <div className="icon">
                                    <DiRuby />
                                </div>
                                Ruby
                            </Col>
                        </Row>
                    )}
                </div>
                
                <div>
                    <div onClick={() => setShowBackend(!showBackend)} style={{fontWeight:"600", fontSize:"22px",cursor: "pointer"}}>
                        {showBackend ? <BiChevronDown /> : <BiChevronRight />} Backend Skills
                    </div>
                    {showBackend && (
                        <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
                            <Col xs={6} md={3} className="tech-icons">
                                <div className="icon">
                                    <DiNodejs />
                                </div>
                                Node.js
                            </Col>
                            <Col xs={6} md={3} className="tech-icons">
                                <div className="icon">
                                    <BiLogoSpringBoot />
                                </div>
                                Spring Boot
                            </Col>
                            <Col xs={6} md={3} className="tech-icons">
                                <div className="icon">
                                    <SiRubyonrails />
                                </div>
                                Ruby on Rails
                            </Col>
                            <Col xs={6} md={3} className="tech-icons">
                                <div className="icon">
                                    <DiMongodb />
                                </div>
                                MongoDB
                            </Col>
                            <Col xs={6} md={3} className="tech-icons">
                                <div className="icon">
                                    <DiPostgresql />
                                </div>
                                PostgreSQL
                            </Col>
                        </Row>
                    )}
                </div>

                <div>
                    <div onClick={() => setShowInfrastructure(!showInfrastructure)} style={{fontWeight:"600", fontSize:"22px",cursor: "pointer"}}>
                        {showInfrastructure ? <BiChevronDown /> : <BiChevronRight />} Infrastructure
                    </div>
                    {showInfrastructure && (
                        <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
                            <Col xs={6} md={3} className="tech-icons">
                                <div className="icon">
                                    <DiGit />
                                </div>
                                Git
                            </Col>
                            <Col xs={6} md={3} className="tech-icons">
                                <div className="icon">
                                    <BiLogoAws />
                                </div>
                                AWS
                            </Col>
                        </Row>
                    )}
                </div>

                <div>
                    <div onClick={() => setShowFrontend(!showFrontend)} style={{fontWeight:"600", fontSize:"22px",cursor: "pointer"}}>
                        {showFrontend ? <BiChevronDown /> : <BiChevronRight />} Frontend
                    </div>
                    {showFrontend && (
                        <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
                            <Col xs={6} md={3} className="tech-icons">
                                <div className="icon">
                                    <DiReact />
                                </div>
                                React
                            </Col>
                        </Row>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Skills;