const INFO = {
	main: {
		title: "Portfolio by pisharan",
		name: "Piyush Sharan",
		email: "pisharan@tamu.edu",
		logo: "../logo.png",
	},

	socials: {
		github: "https://github.com/pi-sharan",
		linkedin: "https://www.linkedin.com/in/piyush-sharan-16456645",
		instagram: "https://instagram.com/py.sharan",
		facebook: "https://facebook.com/thepiyushsharan",
	},

	homepage: {
		title: "Software Engineer, Full-stack web developer, Competitive programmer and amateur Writer.",
		description:
			"Hi! I am a Backend developer with experience in GO, Spring Boot & Node.js. I have experience in building scalable, secure and reliable microservices and web applications using various frameworks and technologies. I enjoy solving complex problems and learning new skills. I am passionate about creating high-quality code that follows best practices and industry standards. I am always looking for new challenges and opportunities to grow as a developer.",
	},

	projects: [
		{
			title: "BochsOS: Comprehensive Operating System",
			description:
				"Texas A&M CSCE 611: Operating Systems. Implemented an end-to-end Operating System comprising of over 20,000 lines of code in C++. Implemented the Page Tables, Virtual Memory manager, Multi-Threaded Scheduler, Device Manager and Basic File System.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos@0.0.3/src/cpp/cpp.png",
			linkText: "View Project",
			link: "https://pi-sharan.github.io/BochsOS-Comprehensive-OS-Development/",
		},
		{
			title: "[Ongoing]: NXTFolio Website",
			description:
				"Creating the official Web Infrastructure for FashioNXT: NXTFolio that enables client devices to complete authentication, create folios & followers, creating job postings, and chat. Deployed on heroku.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos@0.0.3/src/ruby/ruby.png",
			linkText: "View Project",
			link: "https://pi-sharan.github.io/NXTFolio/",
		},
		{
			title: "Online E-Parts Store",
			description:
				"Built Fully functional online store in MERN stack featuring Add items to cart, authentication, place orders, favourite items, etc.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com/pi-sharan/eCommerce-React",
		},
		{
			title: "[Ongoing]: Movie Rating System",
			description:
				"Instigated end-to-end development of Full Stack Web Application portal for adding Reviews, providing ratings for movies. Created Back-end REST APIs using Spring Boot.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/java/java.png",
			linkText: "View Project",
		},
		{
			title: "JobHunter: A Job Recommendation Engine",
			description:
				"Created JobHunter, a complete end-to-end Job Recommendation engine based on Large Language Models. Successfully generates user-specific Job Recommendation based on User's past experience and applications.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos@0.0.3/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com/pi-sharan/JobHunter",
		},
		{
			title: "Humanoid Mime Bot",
			description:
				"Created a Humanoid bot, that mimics actions performed infront of camera in Real-time. Used Human Pose Estimation. Implemented CVPR '16 paper with major enhancements.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com/pi-sharan/Mime-Bot-using-Human-Pose-Estimation",
		},
		{
			title: "EyeGestures: Movement and Vocalization using pupil tracking",
			description:
				"Developed a real-time, fully functional pupil tracker for patients in hospitals who are unable to speak and move normally. Giving them the ability to talk and move.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com/pi-sharan/Eyeball-Tracked-Wheelchair",
		},

	],
};

export default INFO;
