import React, { useEffect } from "react";

import INFO from "../../data/user";
import SEO from "../../data/seo";
import myEducation from "../../data/education";

import "./articles.css";

const Experience = () => {
	return (
		<React.Fragment>
            <div className="articles-main-container">
                <div className="title articles-title">
                    Work Experience
                </div>
                <div className="articles-container">
                    <div className="articles-wrapper">
                    <div className="article">
                            <div className="article-left-side">
                                <img src="tiktok.jpeg" className="education-img"></img>
                            </div>
                            <div className="article-right-side">
                                <div className="article-info">
                                    <div className="article-title">Tiktok / ByteDance - Backend Software Engineer Intern</div>
                                    <div className="article-time">May 2024 - Aug 2024</div>
                                </div>
						        <div className="article-description">
                                    <ul>
                                        <li>Developed & Launched Seller-Incentive Portal on the TikTok Shop, predicted to generate $16.2 million USD annually. Built highly concurrent REST APIs serving high-throughput traffic leveraging tools from <b>Golang, Byte Cloud Services, Redis, MySQL and Kafka based EventBus</b>.</li>
                                        <li>Executed a zero-downtime migration of internal operations management services to company-wide CRM tool, substantially reducing operating costs and decreasing manual effort by 40%.</li>
                                        <li>Redesigned backend architecture for 3 APIs, incorporating concurrent go-routines and pre-populating caching techniques, leading to an average latency reduction from 2 seconds to 900 ms.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="article">
                            <div className="article-left-side">
                                <img src="cisco.jpeg" className="education-img"></img>
                            </div>
                            <div className="article-right-side">
                                <div className="article-info">
                                    <div className="article-title">Cisco Systems - Software Engineer</div>
                                    <div className="article-time">Aug 2022 - Aug 2023</div>
                                </div>
						        <div className="article-description">
                                    <ul>
                                        <li>Worked as a Software Developer for a fast-paced Agile Development team; Designed, Developed, and Tested major features for high-performance backend infrastructure that orchestrates security systems for 35,000+ clients.</li>
                                        <li>Revamped Search API algorithm design with efficient re-indexing and denormalization of tree-based lookup tables, reducing response latency by a staggering 92% <b>(Undergoing Patent)</b></li>
                                        <li>Engineered multiple high-performance multi-threaded APIs and optimized asynchronous callback logic using a publisher-subscriber model, significantly enhancing system efficiency and scalability.</li>
                                        <li>Boosted data integration and integrity for the health monitoring pipeline by developing <b>JavaScript, Python</b> automation scripts and integrating with a Webex Bot, reducing metric access time by 85%.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="article">
                            <div className="article-left-side">
                                <img src="mezami.jpeg" className="education-img"></img>
                            </div>
                            <div className="article-right-side">
                                <div className="article-info">
                                    <div className="article-title">Mezami - Fullstack Software Developer Internship</div>
                                    <div className="article-time">Dec 2020 - March 2021</div>
                                </div>
						        <div className="article-description">
                                    <ul>
                                        <li>Engineered and implemented Full-project lifecycle for Mezami, an event management platform, designed to aid users in organising and attending social events leveraging tools like <b>React.js, TypeScript, JUnit and Spring Boot</b>.</li>
                                        <li>Overhauled the UI/UX using reusable React components, reducing code duplication by 40% and enhancing user interaction and satisfaction by 20% (measured through surveys).</li>
                                        <li>Elevated test coverage to 80%, enhancing code quality and system stability through comprehensive integration and unit tests for Spring Boot based Backend REST APIs.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="article">
                            <div className="article-left-side">
                                <img src="silence.jpeg" className="education-img"></img>
                            </div>
                            <div className="article-right-side">
                                <div className="article-info">
                                    <div className="article-title">Silence Laboratories - Machine Learning Research Intern</div>
                                    <div className="article-time">May 2020 - Aug 2020</div>
                                </div>
						        <div className="article-description">
                                    <ul>
                                        <li>Formulated ML Models for Locomotion Predictions with 92% accuracy using Time-Series Analysis.</li>
                                        <li>Applied PCA and Fourier Transforms for pre-processing and feature extraction, increasing accuracy by 25%.</li>
                                        <li>Validated Model’s Universality by segregating by using Gaussian Mixture and K-Means clustering algorithms.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</React.Fragment>
	);
};

export default Experience;
