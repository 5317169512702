import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import { Helmet } from "react-helmet";
import INFO from "../data/user";


import React, { useState, useEffect } from "react";
import { Container, Navbar, Row } from "react-bootstrap";

import "./styles/resume.css"
import Button from "react-bootstrap/Button";
import pdf from "../ResumeSharanPiyushCo.pdf";
import { AiOutlineDownload } from "react-icons/ai";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;



function ResumeNew() {
  const [width, setWidth] = useState(1200);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  return (
    <React.Fragment>
      <Helmet>
				<title>{`Resume | ${INFO.main.title}`}</title>
			</Helmet>


      <div classname="page-content">
        <div className="resume-content">
          <NavBar active="resume" />
          <div className="content-wrapper">
            <div className="projects-container">
            <Container fluid className="resume-section">
              <Row style={{ justifyContent: "center", position: "relative", marginBottom: "15px"}}>
                <Button
                  variant="primary"
                  href={pdf}
                  target="_blank"
                  style={{ maxWidth: "250px" }}
                >
                  <AiOutlineDownload />
                  &nbsp;Download CV
                </Button>
              </Row>

              <Row className="resume">
                <Document file={pdf} className="d-flex justify-content-center">
                  <Page 
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                      customTextRenderer={false}
                      pageNumber={1} 
                      scale={width > 786 ? 1.7 : 0.6} 
                      />
                </Document>
              </Row>

              <Row style={{ justifyContent: "center", position: "relative" }}>
                <Button
                  variant="primary"
                  href={pdf}
                  target="_blank"
                  style={{ maxWidth: "250px" }}
                >
                  <AiOutlineDownload />
                  &nbsp;Download CV
                </Button>
              </Row>
            </Container>
            </div>

            <div className="page-footer">
                <Footer />
            </div>
          </div>
        </div>
      </div>
        


    </React.Fragment>
    
  );
}

export default ResumeNew;