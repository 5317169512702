import React, { useEffect } from "react";

import INFO from "../../data/user";
import SEO from "../../data/seo";
import myEducation from "../../data/education";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./articles.css";
import { faMedal } from "@fortawesome/free-solid-svg-icons";

const Education = () => {
	return (
		<React.Fragment>
            <div className="articles-main-container">
                <div className="title articles-title">
                    Education
                </div>
                <div className="articles-container">
                    <div className="articles-wrapper">
                        <div className="article">
                            <div className="article-left-side">
                                <img src="tamu.png" className="education-img"></img>
                            </div>
                            <div className="article-right-side">
                                <div className="article-info">
                                    <div className="article-title">Texas A&M University - College Station</div>
                                    <div className="article-time">Aug 2023 - May 2025</div>
                                </div>
						        <div className="article-description">Master's - Computer Science (GPA: 4.0/4.0)</div>
                            </div>
                        </div>
                        <div className="article">
                            <div className="article-left-side">
                                <img src="bhu.jpeg" className="education-img"></img>
                            </div>
                            <div className="article-right-side">
                                <div className="article-info">
                                    <div className="article-title">Indian Institute of Technology (B.H.U) Varanasi</div>
                                    <div className="article-time">Aug 2018 - May 2022</div>
                                </div>
						        <div className="article-description">Bachelor's - Electronics & Communications  (CGPA: 9.71/10.0)</div>                      
						        <div className="article-description">
                                    Achievements: <b>Institute GOLD MEDALIST</b>
                                    <FontAwesomeIcon icon={faMedal} style={{color: "#ffaa00",}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</React.Fragment>
	);
};

export default Education;
